import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a184b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tableCon" }
const _hoisted_2 = { class: "table-box" }
const _hoisted_3 = {
  key: 0,
  class: "bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["content", {'noDataTable': _ctx.tableData.length == 0}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_table, {
          columns: _ctx.columns,
          pagination: false,
          dataSource: _ctx.tableData,
          rowKey: "id",
          scroll: { y: _ctx.tableHeight },
          rowClassName: 
            (record, index) => (index % 2 === 1 ? 'table-striped' : null)
          
        }, null, 8, ["columns", "dataSource", "scroll", "rowClassName"])
      ])
    ], 2),
    (_ctx.tableData && _ctx.tableData.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_pagination, {
            total: _ctx.pagination.total,
            "show-size-changer": "",
            "show-total": (total) => `共 ${total} 条`,
            onChange: _ctx.pageChange,
            onShowSizeChange: _ctx.sizeChange,
            pageSize: _ctx.pagination.pageSize,
            "onUpdate:pageSize": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.pageSize) = $event)),
            pageSizeOptions: ['10', '20', '30', '40', '50']
          }, {
            buildOptionText: _withCtx((props) => [
              _createElementVNode("span", null, _toDisplayString(props.value) + "条/页", 1)
            ]),
            _: 1
          }, 8, ["total", "show-total", "onChange", "onShowSizeChange", "pageSize"])
        ]))
      : _createCommentVNode("", true)
  ]))
}